import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { navigate } from '@reach/router';
import DefaultApi from "../generated/src/api/DefaultApi";
import {ApiClient} from "../generated/src";
import cookie from 'react-cookies'

const LoginComponent = () => {

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const fetchLogin = async () => {
      try {
        await axios.get(window.location.origin + '/login/'); // /accounts/login/
        await sleep(1000)

        const sfcsrftoken = cookie.load('sfcsrftoken');
        setCsrfToken(sfcsrftoken);


      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    fetchLogin();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      const response = await axios.post(window.location.origin + '/login/', formData, { ///accounts/login/
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'X-CSRFToken': formData.get('csrftoken')
        }
      });

      console.log('Login successful:');
      await navigate('/my-libs/');
      console.log('Login successful:');


    } catch (error) {
      console.error('Error logging in:', error);

    }
  }

    return (
        <div>
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" name="login" required/>
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" name="password" required/>
            </div>
            <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}/>
            <button type="submit">Login</button>
          </form>
        </div>
    )

}

export default LoginComponent;
