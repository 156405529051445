import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, navigate } from '@reach/router'
import MediaQuery from 'react-responsive'
import { Modal } from 'reactstrap'
import { siteRoot, canAddRepo, isDocs } from './utils/constants'
import { Utils } from './utils/utils'
import SystemNotification from './components/system-notification'
import SidePanel from './components/side-panel'
import MainPanel from './components/main-panel'
import editUtilities from './utils/editor-utilities'
import MainContentWrapper from './components/main-content-wrapper'
import cookie from 'react-cookies'
import axios from 'axios'

import './css/layout.css'
import './css/toolbar.css'
import './css/search.css'

import ApiRequestComponent from './rbi/components/ApiRequestComponent'
import LoginComponent from './rbi/components/LoginComponent'
import Loading from './components/loading'

const ShareAdminUploadLinks = React.lazy(() => import('./pages/share-admin/upload-links'))
const ShareAdminShareLinks = React.lazy(() => import('./pages/share-admin/share-links'))
const ShareAdminFolders = React.lazy(() => import('./pages/share-admin/folders'))
const ShareAdminLibraries = React.lazy(() => import('./pages/share-admin/libraries'))
const OCMViaWebdav = React.lazy(() => import('./pages/ocm-via-webdav/ocm-via-webdav'))
const ShareWithOCM = React.lazy(() => import('./pages/share-with-ocm/shared-with-ocm'))
const SharedLibraries = React.lazy(() => import('./pages/shared-libs/shared-libs'))
const LinkedDevices = React.lazy(() => import('./pages/linked-devices/linked-devices'))
const Starred = React.lazy(() => import('./pages/starred/starred'))
const DraftsView = React.lazy(() => import('./pages/drafts/drafts-view'))
const FilesActivities = React.lazy(() => import('./pages/dashboard/files-activities'))
const OCMRepoDir = React.lazy(() => import('./pages/share-with-ocm/remote-dir-view'))
const MyLibraries = React.lazy(() => import('./pages/my-libs/my-libs'))
const MyLibDeleted = React.lazy(() => import('./pages/my-libs/my-libs-deleted'))
const PublicSharedView = React.lazy(() => import('./pages/shared-with-all/public-shared-view'))
const LibContentView = React.lazy(() => import('./pages/lib-content-view/lib-content-view'))
const Group = React.lazy(() => import('./pages/groups/group-view'))
const Groups = React.lazy(() => import('./pages/groups/groups-view'))
const InvitationsView = React.lazy(() => import('./pages/invitations/invitations-view'))
const Wikis = React.lazy(() => import('./pages/wikis/wikis'))
const DraftContent = React.lazy(() => import('./pages/drafts/draft-content'))

const FilesActivitiesWrapper = MainContentWrapper(FilesActivities)
const DraftsViewWrapper = MainContentWrapper(DraftsView)
const StarredWrapper = MainContentWrapper(Starred)
const LinkedDevicesWrapper = MainContentWrapper(LinkedDevices)
const SharedLibrariesWrapper = MainContentWrapper(SharedLibraries)
const SharedWithOCMWrapper = MainContentWrapper(ShareWithOCM)
const OCMViaWebdavWrapper = MainContentWrapper(OCMViaWebdav)
const ShareAdminLibrariesWrapper = MainContentWrapper(ShareAdminLibraries)
const ShareAdminFoldersWrapper = MainContentWrapper(ShareAdminFolders)
const ShareAdminShareLinksWrapper = MainContentWrapper(ShareAdminShareLinks)
const ShareAdminUploadLinksWrapper = MainContentWrapper(ShareAdminUploadLinks)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isSidePanelClosed: false,
      draftCounts: 0,
      draftList: [],
      isLoadingDraft: true,
      currentTab: '/',
      pathPrefix: [],
    }
    this.dirViewPanels = ['my-libs', 'shared-libs', 'org'] // and group
    window.onpopstate = this.onpopstate
  }

  onpopstate = event => {
    if (event.state && event.state.currentTab && event.state.pathPrefix) {
      let { currentTab, pathPrefix } = event.state
      this.setState({ currentTab, pathPrefix })
    }
  }

  componentWillMount() {
    axios.get(window.location.origin + '/set-cookie-token-index').then(() => {
      cookie.load('sfcsrftoken')
    })

    if (!Utils.isDesktop()) {
      this.setState({
        isSidePanelClosed: true,
      })
    }
  }

  navigateClientUrlToLib = () => {
    if (window.location.hash && window.location.hash.indexOf('common/lib') != -1) {
      let splitUrlArray = window.location.hash.split('/')
      let repoID = splitUrlArray[splitUrlArray.length - 2]
      let url = siteRoot + 'library/' + repoID + '/'
      navigate(url, { repalce: true })
    }
  }

  componentDidMount() {
    // url from client  e.g. http://127.0.0.1:8000/#common/lib/34e7fb92-e91d-499d-bcde-c30ea8af9828/
    // navigate to library page http://127.0.0.1:8000/library/34e7fb92-e91d-499d-bcde-c30ea8af9828/
    this.navigateClientUrlToLib()

    // e.g.  from http://127.0.0.1:8000/drafts/reviews/
    // get reviews
    // TODO: need refactor later
    let href = window.location.href.split('/')
    if (isDocs) {
      this.getDrafts()
    }
    this.setState({ currentTab: href[href.length - 2] })
  }

  getDrafts = () => {
    editUtilities.listDrafts().then(res => {
      this.setState({
        draftCounts: res.data.draft_counts,
        draftList: res.data.data,
        isLoadingDraft: false,
      })
    })
  }

  updateDraftsList = draft_id => {
    this.setState({
      draftCounts: this.state.draftCounts - 1,
      draftList: this.state.draftList.filter(draft => draft.id != draft_id),
    })
  }

  onCloseSidePanel = () => {
    this.setState({
      isSidePanelClosed: !this.state.isSidePanelClosed,
    })
  }

  onShowSidePanel = () => {
    this.setState({
      isSidePanelClosed: !this.state.isSidePanelClosed,
    })
  }

  onSearchedClick = selectedItem => {
    if (selectedItem.is_dir === true) {
      this.setState({ currentTab: '', pathPrefix: [] })
      let url = siteRoot + 'library/' + selectedItem.repo_id + '/' + selectedItem.repo_name + selectedItem.path
      navigate(url, { repalce: true })
    } else {
      let url = siteRoot + 'lib/' + selectedItem.repo_id + '/file' + Utils.encodePath(selectedItem.path)
      let isWeChat = Utils.isWeChat()
      if (!isWeChat) {
        let newWindow = window.open('about:blank')
        newWindow.location.href = url
      } else {
        location.href = url
      }
    }
  }

  onGroupChanged = groupID => {
    setTimeout(function () {
      let url
      if (groupID) {
        url = siteRoot + 'group/' + groupID + '/'
      } else {
        url = siteRoot + 'groups/'
      }
      window.location = url.toString()
    }, 1)
  }

  tabItemClick = (tabName, groupID) => {
    let pathPrefix = []
    if (groupID || this.dirViewPanels.indexOf(tabName) > -1) {
      pathPrefix = this.generatorPrefix(tabName, groupID)
    }
    this.setState(
      {
        currentTab: tabName,
        pathPrefix: pathPrefix,
      },
      () => {
        let { currentTab, pathPrefix } = this.state
        window.history.replaceState({ currentTab: currentTab, pathPrefix: pathPrefix }, null)
      }
    )
    if (!Utils.isDesktop() && !this.state.isSidePanelClosed) {
      this.setState({ isSidePanelClosed: true })
    }
  }

  generatorPrefix = (tabName, groupID) => {
    let pathPrefix = []
    if (groupID) {
      let navTab1 = {
        url: siteRoot + 'groups/',
        showName: 'Groups',
        name: 'groups',
        id: null,
      }
      let navTab2 = {
        url: siteRoot + 'group/' + groupID + '/',
        showName: tabName,
        name: tabName,
        id: groupID,
      }
      pathPrefix.push(navTab1)
      pathPrefix.push(navTab2)
    } else {
      let navTab = {
        url: siteRoot + tabName + '/',
        showName: this.getTabShowName(tabName),
        name: tabName,
        id: null,
      }
      pathPrefix.push(navTab)
    }
    return pathPrefix
  }

  getTabShowName = tabName => {
    if (tabName === 'my-libs') {
      return 'Libraries'
    }
    if (tabName === 'shared-libs') {
      return 'Shared with me'
    }
    if (tabName === 'org') {
      return 'Shared with all'
    }
  }

  toggleSidePanel = () => {
    this.setState({
      isSidePanelClosed: !this.state.isSidePanelClosed,
    })
  }

  render() {
    let { currentTab, isSidePanelClosed } = this.state

    const home = canAddRepo ? (
      <MyLibraries path={siteRoot} onShowSidePanel={this.onShowSidePanel} onSearchedClick={this.onSearchedClick} />
    ) : (
      <SharedLibrariesWrapper
        path={siteRoot}
        onShowSidePanel={this.onShowSidePanel}
        onSearchedClick={this.onSearchedClick}
      />
    )

    return (
      <React.Fragment>
        <SystemNotification />
        <div id='main'>
          <SidePanel
            isSidePanelClosed={this.state.isSidePanelClosed}
            onCloseSidePanel={this.onCloseSidePanel}
            currentTab={currentTab}
            tabItemClick={this.tabItemClick}
            draftCounts={this.state.draftCounts}
          />
          <MainPanel>
            <React.Suspense fallback={<Loading />}>
              <Router className='reach-router'>
                {home}
                <FilesActivitiesWrapper
                  path={siteRoot + 'dashboard'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <DraftsViewWrapper
                  path={siteRoot + 'drafts'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                >
                  <DraftContent
                    path='/'
                    getDrafts={this.getDrafts}
                    isLoadingDraft={this.state.isLoadingDraft}
                    draftList={this.state.draftList}
                    updateDraftsList={this.updateDraftsList}
                  />
                </DraftsViewWrapper>
                <StarredWrapper
                  path={siteRoot + 'starred'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <LinkedDevicesWrapper
                  path={siteRoot + 'linked-devices'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <ShareAdminLibrariesWrapper
                  path={siteRoot + 'share-admin-libs'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <ShareAdminFoldersWrapper
                  path={siteRoot + 'share-admin-folders'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <ShareAdminShareLinksWrapper
                  path={siteRoot + 'share-admin-share-links'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <ShareAdminUploadLinksWrapper
                  path={siteRoot + 'share-admin-upload-links'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <SharedLibrariesWrapper
                  path={siteRoot + 'shared-libs'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <SharedWithOCMWrapper
                  path={siteRoot + 'shared-with-ocm'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <OCMViaWebdavWrapper
                  path={siteRoot + 'ocm-via-webdav'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />
                <MyLibraries
                  path={siteRoot + 'my-libs'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />

                <MyLibDeleted path={siteRoot + 'my-libs/deleted/'} onSearchedClick={this.onSearchedClick} />

                <LibContentView
                  path={siteRoot + 'library/:repoID/*'}
                  pathPrefix={this.state.pathPrefix}
                  onMenuClick={this.onShowSidePanel}
                  onTabNavClick={this.tabItemClick}
                />

                <OCMRepoDir
                  path={siteRoot + 'remote-library/:providerID/:repoID/*'}
                  pathPrefix={this.state.pathPrefix}
                  onMenuClick={this.onShowSidePanel}
                  onTabNavClick={this.tabItemClick}
                />

                <Groups
                  path={siteRoot + 'groups'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />

                <Group
                  path={siteRoot + 'group/:groupID'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                  onTabNavClick={this.tabItemClick}
                  onGroupChanged={this.onGroupChanged}
                />

                <Wikis
                  path={siteRoot + 'published'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />

                <PublicSharedView
                  path={siteRoot + 'org/'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                  onTabNavClick={this.tabItemClick}
                />

                <InvitationsView
                  path={siteRoot + 'invitations/'}
                  onShowSidePanel={this.onShowSidePanel}
                  onSearchedClick={this.onSearchedClick}
                />

                <LoginComponent path={siteRoot + '/accounts/login/'} />
              </Router>
            </React.Suspense>
          </MainPanel>
          <MediaQuery query='(max-width: 767.8px)'>
            <Modal
              zIndex='1030'
              isOpen={!isSidePanelClosed}
              toggle={this.toggleSidePanel}
              contentClassName='d-none'
            ></Modal>
          </MediaQuery>
        </div>
        <ApiRequestComponent />
      </React.Fragment>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('wrapper'))
